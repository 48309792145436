/** @jsx jsx */

import { useState, useReducer, useEffect } from 'react'
import { jsx, css } from '@emotion/core'
import TextInput from 'ava/TextInput'
import Button, { ButtonTypes } from 'ava/Button'
import ReactionPicker from './ReactionPicker'
import MessagesContainer from './MessagesContainer'
import Icon, { IconNames } from 'ava/icons/Icons'
import { Colors } from 'ava/css/constants'
import Live from 'ava/Live'

/*<iframe
          width="781"
          height="462"
          src="https://www.youtube.com/embed/UjLnvXpkq68"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        ></iframe> */

const _messages = [
  { username: 'SHREK', message: 'FIRST' },
  { username: 'davidguetta', message: 'shout out to his family!' },
  {
    username: 'champagnePapi',
    message: `Got a dance, but it’s some street shit.`,
  },
  {
    username: 'stereo',
    message: 'BREONNA TAYLOR',
  },
  { username: 'donaldTrump', message: `hey guys!` },
]

const DEMO_URL =
  'https://s3-us-west-2.amazonaws.com/nightlist.app/asset/demo-video.mp4'

const initialState = { messages: [] }

const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD_MESSAGE':
      return {
        ...state,
        messages: [action.message, ...state.messages],
      }
    default:
      throw new Error()
  }
}

const Video = ({ isOff }: { isOff?: boolean }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [currentMessage, setCurrentMessage] = useState('')

  // Demo messages push
  useEffect(() => {
    if (!isOff) {
      let i = 0
      const interval = setInterval(() => {
        dispatch({
          type: 'ADD_MESSAGE',
          message: _messages[i],
        })
        i += 1
        if (i >= _messages.length) {
          clearInterval(interval)
        }
      }, Math.random() * 3000 + 2000)
      return () => clearInterval(interval)
    }
  }, [isOff])

  const submitMessage = () => {
    const message = currentMessage.trim()
    if (message.length > 0) {
      dispatch({
        type: 'ADD_MESSAGE',
        message: { username: 'you', message },
      })
      setCurrentMessage('')
    }
  }
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        overflow: hidden;
        width: 98%;
      `}
    >
      <div
        css={css`
          display: flex;
          position: relative;
          width: 100%;
          background-color: black;
          flex: 1 1 auto;
        `}
      >
        <video
          playsInline
          width="100%"
          height="100%"
          autoPlay={true}
          muted={true}
          loop={true}
          preload="auto"
          controls={true}
        >
          <source src={DEMO_URL} type="video/mp4" />
        </video>

        <div
          css={css`
            position: absolute;
            padding: 1.25rem;
            display: flex;
            width: 100%;
            height: 100%;
            flex-direction: column;
            justify-content: space-between;
          `}
        >
          <Live
            css={css`
              align-self: flex-end;
            `}
          />
          <div
            css={css`
              display: flex;
              width: 100%;
            `}
          >
            <MessagesContainer
              css={css`
                flex: 1 1 auto;
              `}
              messages={state.messages}
            />

            <div
              css={css`
                flex: 0 0 auto;
                display: flex;
                align-items: flex-end;
              `}
            >
              <ReactionPicker isOff={isOff} />
            </div>
          </div>
        </div>
      </div>
      <form
        css={css`
          display: flex;
          background: ${Colors.BLACK};
          padding: 1rem 1.25rem;
          border-radius: 0 0 10px 10px;
          flex: 0 0 auto;
        `}
      >
        <TextInput
          css={css`
            margin-bottom: 0;
            flex: 1 1 auto;
            margin-right: 1.25rem;
          `}
          placeholder="Send a message"
          value={currentMessage}
          onChange={setCurrentMessage}
        />
        <Button
          css={css`
            background-color: ${Colors.YELLOW};
            flex: 0 0 auto;
          `}
          type={ButtonTypes.Submit}
          onClick={submitMessage}
        >
          <Icon iconName={IconNames.ChatboxEllipses} />
        </Button>
      </form>
    </div>
  )
}

export default Video
