/** @jsx jsx */

import React from 'react'
import { jsx, css } from '@emotion/core'
import Video from './Video'
import Stereo from 'ava/Stereo'
import { Colors } from 'ava/css/constants'
import { from } from '@apollo/client'

interface Props {
  hideLogo?: boolean
  className?: string
  // turns off animations and player
  isOff?: boolean
}

const StreamPlayer = ({ className, hideLogo, isOff }: Props) => {
  return (
    <div
      css={css`
        position: relative;
        display: flex;
      `}
      className={className}
    >
      {!hideLogo && (
        <div
          css={css`
            display: flex;
            position: absolute;
            justify-self: flex-start;
            margin-left: 3.75rem;
            margin-top: 3.75rem;
          `}
        >
          <Stereo />
        </div>
      )}

      <div
        css={css`
          display: flex;
          position: absolute;
          align-self: center;
          justify-content: space-around;
          width: 100%;
          height: 100%;
        `}
      >
        <Video isOff={false} />
      </div>
    </div>
  )
}

export default StreamPlayer
