/** @jsx jsx */

import React, { ReactNode } from 'react'
import { jsx, css } from '@emotion/core'
import { Colors } from './css/constants'

export enum ButtonTypes {
  Button = 'button',
  Reset = 'reset',
  Submit = 'submit',
}

// TODO: optional onClick only for ButtonTypes.Submit
type Props = {
  type?: ButtonTypes
  onClick?: () => void
  children: ReactNode
  className?: string
}

const buttonStyle = css`
  flex: 1 0 auto;
  color: ${Colors.BLACK};
  cursor: pointer;
  padding: 0.5em 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
`

const Button = ({
  type = ButtonTypes.Button,
  onClick,
  children,
  className,
}: Props) => {
  return (
    <button
      css={buttonStyle}
      type={type}
      onClick={event => {
        if (!onClick) return
        event.preventDefault()
        onClick()
      }}
      className={className}
      data-analytics="landing-intake-button"
    >
      {children}
    </button>
  )
}

export default Button
