import React, {
  createContext,
  useReducer,
  useContext,
  Dispatch,
  useEffect,
} from 'react'
import {
  Message,
  MessagesActionTypes,
  SEND_MESSAGE,
  RECEIVE_MESSAGE,
  SET_DISPLAYNAME,
} from './types'
import artist from 'event/static/jayrahx'
import FirebaseClient from 'firebase/FirebaseClient'

const eventId = artist.eventId

interface MessagesState {
  messages: Message[]
  displayName: string
}

const initialState: MessagesState = { messages: [], displayName: '' }

const MessagesStateContext = createContext<MessagesState>(initialState)
const MessagesDispatchContext = createContext<Dispatch<MessagesActionTypes>>(
  () => null
)

const reducer = (
  state: MessagesState,
  action: MessagesActionTypes
): MessagesState => {
  switch (action.type) {
    case SEND_MESSAGE:
      FirebaseClient.database()
        .ref(`chats/${eventId}`)
        .push({
          ...action.message,
          username: state.displayName || 'anonymous',
        })
      return state
    case RECEIVE_MESSAGE:
      return {
        ...state,
        messages: [action.message, ...state.messages],
      }
    case SET_DISPLAYNAME:
      return {
        ...state,
        displayName: action.displayName,
      }
    default:
      throw new Error()
  }
}

export const MessagesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    FirebaseClient.database()
      .ref(`chats/${eventId}`)
      .on('value', async data => {
        data.forEach(snap => {
          const message = snap.val()
          dispatch({
            type: RECEIVE_MESSAGE,
            message,
          })
        })
      })
  }, [])

  return (
    <MessagesStateContext.Provider value={state}>
      <MessagesDispatchContext.Provider value={dispatch}>
        {children}
      </MessagesDispatchContext.Provider>
    </MessagesStateContext.Provider>
  )
}

export const useMessagesState = () => {
  const context = useContext(MessagesStateContext)

  if (context === undefined)
    throw new Error('useMessagesState must be used within a MessagesProvider')

  return context
}

export const useMessagesDispatch = () => {
  const context = useContext(MessagesDispatchContext)

  if (context === undefined)
    throw new Error(
      'useMessagesDispatch must be used within a MessagesProvider'
    )

  return context
}
