/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import { Fonts } from 'ava/css/constants'
import { a } from 'react-spring/web.cjs'

interface Props {
  username: string
  message: string
  uid: string
  timestamp: string | number
  style?: Object
  className?: string
}

const strokeColor = 'rgba(0, 0, 0, 0.38)'

const Message = ({ username, message, style, className }: Props) => {
  return (
    <a.div
      css={css`
        ${Fonts.RobotoNormal}
        line-height: 1.25;
        font-size: 1.125rem;
        color: rgba(255, 255, 255, 0.87);
        text-shadow: -1px -1px 0 ${strokeColor}, 1px -1px 0 ${strokeColor},
          -1px 1px 0 ${strokeColor}, 1px 1px 0 ${strokeColor};
      `}
      className={className}
      style={style}
    >
      <strong
        css={css`
          ${Fonts.RobotoBold}
        `}
      >
        {username}:
      </strong>{' '}
      {message}
    </a.div>
  )
}

export default Message
