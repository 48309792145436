/** @jsx jsx */

import React from 'react'
import { jsx, css } from '@emotion/core'
import { Colors } from './css/constants'

export enum TextInputTypes {
  Text = 'text',
  Password = 'password',
  Email = 'email',
}

interface Props {
  type?: TextInputTypes
  placeholder?: string
  value: string
  onChange: (value: string, event?: React.ChangeEvent) => void
  className?: string
  autocomplete?: string
  name?: string
  error?: string
  touched?: boolean
}

const TextInput = ({
  type = TextInputTypes.Text,
  placeholder,
  value,
  onChange,
  className,
  autocomplete,
  name,
}: Props) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        margin-bottom: 0.8em;
        border: 1px solid ${Colors.WHITE};
      `}
      className={className}
    >
      <input
        css={css`
          background-color: transparent;
          color: ${Colors.WHITE};
          outline: none;
          border-style: none;
          flex: 1 0 auto;
          font-size: 1rem;
          padding: calc(1rem - 2px) calc(1.25rem - 2px);

          :placeholder {
            color: ${Colors.WHITE};
          }
        `}
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={event => {
          event.preventDefault()
          onChange(event.target.value, event)
        }}
        autoComplete={autocomplete}
        name={name}
        id={name}
      />
    </div>
  )
}

export default TextInput
