/** @jsx jsx */

import { times } from 'lodash'
import React from 'react'
import { jsx, css } from '@emotion/core'
import { LayoutMain } from 'ava/Layout'
import { Fonts, Colors } from 'ava/css/constants'
import StreamPlayer from 'stream-player/DemoStreamPlayer'
import Icon, { IconNames } from 'ava/icons/Icons'
import ReactMarkdown from 'react-markdown'
import media, { Breakpoints } from 'ava/utils/media'
import Header from 'landing/Header'

import Footer from 'landing/Footer'
import scottDance2 from 'landing/images/scott-dance-2.jpeg'
import vsunDance from 'landing/images/vsun-dance.jpeg'
import vsunDance2 from 'landing/images/vsun-dance-2.jpeg'
import scottSmoke from 'landing/images/scott-smoke.jpeg'
import blackStar from 'landing/images/black-star.jpeg'
import { Button } from '@chakra-ui/core'

export const Grid = ({
  className,
  children,
}: {
  className?: string
  children: React.ReactNode
}) => (
  <div
    css={css`
      display: grid;
      grid-template-columns: repeat(12, [column] 1fr);
      grid-column-gap: 20px;
      padding: 0;
      padding-left: max(20px, calc(20px + env(safe-area-inset-left)));
      padding-right: max(20px, calc(20px + env(safe-area-inset-right)));
      margin-bottom: var(--margin-tertiary);
      width: 100%;
      max-width: 700px;

      ${media.greaterThan(Breakpoints.MediumScreen)`
        max-width: 1040px;
      `}

      ${media.greaterThan(Breakpoints.LargeScreen)`
        max-width: 1245px;
      `}
    `}
    className={className}
  >
    {children}
  </div>
)

const sections = [
  {
    title: 'Take your tour *online.*',
    copy:
      'We work with you to create and customize your event. Get a link to promote to your fans. Make money from selling tickets.',
    iconName: IconNames.CloudUpload,
    color: Colors.BLUE,
  },
  {
    title: '*Merch* sales, zero effort.',
    copy:
      "You provide the design. We handle fulfillment. It's never been easier to put your logo on a COVID facemask.",
    iconName: IconNames.Shirt,
    color: Colors.PURPLE,
  },
  {
    title: 'Get *paid,* straight up.',
    copy:
      'We don’t play with your money. Set your ticket price and get paid within 72 hours after your show, as soon as funds clear.',
    iconName: IconNames.Card,
    color: Colors.GREEN,
  },
  {
    title: 'The *most lit* venue on the internet.',
    copy:
      "Interact with your fans with live chat and emotes. We're building a streaming platform that feels like the club.",
    iconName: IconNames.Flame,
    color: Colors.RED,
  },
  {
    title: '*Kill* your masters.',
    copy:
      'The industry is giving you a slice when it should be the other way around. Capture your value by selling directly to your fans.',
    iconName: IconNames.Skull,
    color: Colors.YELLOW,
  },
]

export const Section = ({ title, copy, iconName, color }) => (
  <section
    css={css`
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: var(--margin-primary);
    `}
  >
    <Grid
      css={css`
        margin-bottom: 1.5rem;

        h2 {
          font-size: calc(12px + 6vw);
          line-height: 1;
          max-width: 915px;
          grid-column: 1 / -1;
          background-color: ${Colors.BLACK};
          padding: 1.5rem;
          ${Fonts.AcuminProBlack}
          em {
            color: ${color};
            font-style: normal;
          }

          ${media.greaterThan(Breakpoints.LargeScreen)`
            font-size: 80px;
            grid-column-end: span 6;
            grid-column-start: 2 column;
          `}
        }
      `}
    >
      <Icon
        css={css`
          grid-column-start: 1;
          grid-column-end: span 1;
          fill: ${color};
          width: 100%;
          height: unset;
          align-self: center;
          display: none;

          ${media.greaterThan(Breakpoints.LargeScreen)`
            display: block;
          `}
        `}
        iconName={iconName}
      />

      <ReactMarkdown source={`## ${title}`} />
    </Grid>

    <Grid>
      <h3
        css={css`
          font-size: 1.5rem;
          grid-column: 1 / -1;
          background-color: ${Colors.BLACK};
          padding: 1.5rem;
          line-height: 1.2;

          ${media.greaterThan(Breakpoints.MediumScreen)`
            grid-column-end: span 8;
          `}
          ${media.greaterThan(Breakpoints.LargeScreen)`
            grid-column-end: span 8;
            grid-column-start: column 2;
          `}
          ${media.greaterThan(Breakpoints.XLargeScreen)`
            grid-column-end: span 7;
          `}
        `}
      >
        {copy}
      </h3>
    </Grid>
  </section>
)

const Demo = () => {
  return (
    <section
      css={css`
        display: flex;
        justify-content: center;
        align-items: stretch;
        padding: 0 20px;
        margin-top: var(--margin-secondary);
        margin-bottom: var(--margin-secondary);
        height: calc(100vh - 40px - 2px - 1rem - 8px);
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          overflow: hidden;
          border: 1px solid rgba(255, 255, 255, 0.38);
          border-radius: 4px;
          width: 100%;

          ${media.greaterThan(Breakpoints.LargeScreen)`
          width: 1000px;
        `}

          ${media.greaterThan(Breakpoints.XLargeScreen)`
          width: 1200px;
        `}
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid rgba(255, 255, 255, 0.38);
            padding: 4px;
          `}
        >
          <div
            css={css`
              display: flex;
            `}
          >
            {times(3, idx => {
              return (
                <div
                  key={idx}
                  css={css`
                    height: 1rem;
                    width: 1rem;
                    margin-right: 4px;
                    border-radius: 50%;
                    background-color: rgba(255, 255, 255, 0.2);
                  `}
                />
              )
            })}
          </div>

          <div
            css={css`
              border: 1px solid rgba(255, 255, 255, 0.38);
              border-radius: 4px;
              display: flex;
              align-items: center;
              width: 400px;
              padding: 2px;
              justify-content: space-between;
            `}
          >
            <Icon
              css={css`
                fill: ${Colors.WHITE};
              `}
              iconName={IconNames.Radio}
            />
            <div>stereo.live</div>
            <Icon
              css={css`
                visibility: hidden;
              `}
              iconName={IconNames.Radio}
            />
          </div>

          <div
            css={css`
              display: flex;
              visibility: hidden;
            `}
          >
            {times(3, idx => {
              return (
                <div
                  key={idx}
                  css={css`
                    height: 1rem;
                    width: 1rem;
                    margin-right: 2px;
                    border-radius: 50%;
                    background-color: ${Colors.PRIMARY_80};
                  `}
                />
              )
            })}
          </div>
        </div>
        <StreamPlayer
          css={css`
            height: 100%;
            flex: 1;
            width: 100%;
          `}
          hideLogo
        />
      </div>
    </section>
  )
}

const TwitterCTA = ({ className }: { className?: string }) => (
  <Button
    bgColor="yellow.500"
    color="black"
    size="lg"
    alignItems="center"
    css={css`
      background-color: ${Colors.YELLOW};
      font-size: calc(12px + 1.6vw);
      max-width: 900px;

      ${media.greaterThan(Breakpoints.Mobile)`
        font-size: calc(12px + 2vw);
      `}

      ${media.greaterThan(Breakpoints.Tablet)`
        font-size: calc(14px + 1.2vw);
      `}

      ${media.greaterThan(Breakpoints.MediumScreen)`
        font-size: 26px;
      `}

      ${media.greaterThan(Breakpoints.LargeScreen)`
        font-size: 28px;
      `}
    `}
    onClick={() => {
      window.open(process.env.NEXT_PUBLIC_ARTIST_FORM!, '_blank').focus()
    }}
    className={className}
  >
    Create a live stream concert
  </Button>
)

const LandingPage = () => {
  return (
    <React.Fragment>
      <Header />
      <LayoutMain
        css={css`
          --margin-tertiary: 30px;
          --margin-primary: calc(2 * var(--margin-tertiary));
          --margin-secondary: calc(1.2 * var(--margin-tertiary));
          --margin-cta: calc(1.5 * var(--margin-tertiary));

          ${media.greaterThan(Breakpoints.Mobile)`
          --margin-tertiary: 34px;
        `}

          ${media.greaterThan(Breakpoints.Tablet)`
          --margin-primary: calc(3 * var(--margin-tertiary));
          --margin-secondary: calc(1.8 * var(--margin-tertiary));
        `}
        `}
      >
        <section
          css={css`
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          `}
        >
          <h1
            css={css`
              margin-top: 1em;
              font-size: calc(6px + 8vw);
              line-height: 1;
              max-width: 1000px;
              margin-bottom: 0.5em;
              ${Fonts.AcuminProBlack}
              em {
                font-style: normal;
                color: ${Colors.YELLOW};
              }

              ${media.greaterThan(Breakpoints.Mobile)`
                font-size: calc(12px + 8vw);
              `}

              ${media.greaterThan(Breakpoints.MediumScreen)`
            font-size: 80px;
              `}
            `}
          >
            The streaming platform for <em>paid</em> concerts
          </h1>
          <h2
            css={css`
              font-size: calc(12px + 1.6vw);
              margin: 0.2em auto 1.4em;
              width: 80vw;
              max-width: 900px;
              line-height: 1.2;

              ${media.greaterThan(Breakpoints.Mobile)`
                font-size: calc(12px + 2vw);
              `}

              ${media.greaterThan(Breakpoints.Tablet)`
                font-size: calc(14px + 1.2vw);
              `}

              ${media.greaterThan(Breakpoints.MediumScreen)`
                font-size: 26px;
              `}

              ${media.greaterThan(Breakpoints.LargeScreen)`
                font-size: 28px;
              `}

              em {
                color: ${Colors.YELLOW};
                font-style: normal;
              }
            `}
          >
            Sell tickets + merch and perform live on stream with Stereo.
            <br />
            Built for independent artists, by independent developers.
          </h2>
          <TwitterCTA />
        </section>

        <Demo />
        <div
          css={css`
            display: flex;
            flex-direction: column;
            background-image: url(${scottSmoke}), url(${scottDance2}),
              url(${vsunDance2}), url(${vsunDance}), url(${blackStar});
            background-position: 100% 10%, calc(100% - 460px) 10%, 0% 50%,
              460px 50%, 100% 90%;
            background-size: 450px 600px, 450px 600px, 450px 600px, 450px 600px,
              50vw 50vw;
            background-repeat: no-repeat;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            {sections.map(section => {
              return <Section key={section.iconName} {...section} />
            })}

            <section
              css={css`
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: calc(1.32 * var(--margin-primary)) 0;
              `}
            >
              <Grid>
                <div
                  css={css`
                    grid-column: 1/-1;
                    padding: 1.5rem;

                    ${media.greaterThan(Breakpoints.LargeScreen)`
                      grid-column-start: 2;
                      grid-column-end: span 11;
                    `}
                  `}
                >
                  <h2
                    css={css`
                      margin-bottom: 3rem;
                      font-size: calc(12px + 6vw);
                      line-height: 1;
                      max-width: 915px;
                      ${Fonts.AcuminProBlack}

                      ${media.greaterThan(Breakpoints.LargeScreen)`
                   font-size: 80px;
                  `}
                    `}
                  >
                    We want a world where music stays independent.
                    <br />
                    We are not for sale.
                    <br />
                    Join us.
                  </h2>
                </div>
              </Grid>
              <TwitterCTA />
            </section>
          </div>
        </div>

        <Footer />
      </LayoutMain>
    </React.Fragment>
  )
}

export default LandingPage
