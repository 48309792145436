import jayrahxTee1 from './jayrahxTee1.png'
import jayrahxTee2 from './jayrahxTee2.png'

export default {
  name: 'wolfe is raising money for the bond project',
  description: 'wolfe description',
  tickets: [
    {
      title: 'General admission ticket - pay what you can',
      variantId: 'pwyw',
      pricing: { displayPrice: '', price: 0 },
    },
  ],
  eventId: '9e3a4ad3-0bab-42e6-a539-67ab3fae4fc8',
  products: [
    {
      title: 'FVCK OPS Unisex Tee',
      pricing: { displayPrice: '$25.00', price: 2500 },
      variants: [
        { label: 'S', variantId: 'price_1HANpZGc4HaxduNIzgV8Hk0E' },
        { label: 'M', variantId: 'price_1HANppGc4HaxduNIpPSNDgFN' },
        { label: 'L', variantId: 'price_1HANq1Gc4HaxduNIS7u3Z0i4' },
        { label: 'XL', variantId: 'price_1HANqTGc4HaxduNILhQoirId' },
      ],
      imgs: [jayrahxTee1, jayrahxTee2],
    },
  ],
}
