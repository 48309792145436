import { css } from '@emotion/core'

// usually set as min-widths
export enum Breakpoints {
  Mobile = '375px',
  Tablet = '768px',
  MediumScreen = '1024px',
  LargeScreen = '1280px',
  XLargeScreen = '1440px',
}

function getSizeFromBreakpoint(breakpointValue, breakpoints = {}) {
  if (breakpoints[breakpointValue]) {
    return breakpoints[breakpointValue]
  } else if (parseInt(breakpointValue)) {
    return breakpointValue
  } else {
    console.error(
      'emotion-media-query: No valid breakpoint or size specified for media.'
    )
    return '0'
  }
}

/**
 * Media query generator
 * @param {Object} [defaultBreakpoints] breakpoints - Map labels to breakpoint sizes
 * @return {Object} - Media generators for each breakpoint
 */
export function generateMedia(breakpoints = Breakpoints) {
  // const lessThan = breakpoint => (...args) => css`
  //   @media (max-width: ${getSizeFromBreakpoint(breakpoint, breakpoints)}) {
  //     ${css(...args)}
  //   }
  // `

  const greaterThan = breakpoint => (...args) => css`
    @media (min-width: ${getSizeFromBreakpoint(breakpoint, breakpoints)}) {
      ${css(...args)}
    }
  `

  // const between = (firstBreakpoint, secondBreakpoint) => (...args) => css`
  //   @media (min-width: ${getSizeFromBreakpoint(
  //       firstBreakpoint,
  //       breakpoints
  //     )}) and (max-width: ${getSizeFromBreakpoint(
  //       secondBreakpoint,
  //       breakpoints
  //     )}) {
  //     ${css(...args)}
  //   }
  // `

  return {
    greaterThan,
  }
}

export default generateMedia()
