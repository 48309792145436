/** @jsx jsx */

import { useReducer, useEffect } from 'react'
import { jsx, css } from '@emotion/core'
import { useSpring } from 'react-spring/universal.cjs'
import { a } from 'react-spring/web.cjs'
import { uniqueId } from 'lodash'
import { IconButton, Box, Stack, StackProps } from '@chakra-ui/core'

enum Emojis {
  Fire,
  Fist,
  Heart,
}

const EmojiUnicode = {
  [Emojis.Fire]: '🔥',
  [Emojis.Heart]: '💖',
  [Emojis.Fist]: '✊🏿',
}

const ReactionAnimation = ({ emoji }) => {
  const { x } = useSpring({
    from: { x: -10 * Math.random() },
    loop: { reverse: true },
    to: { x: 10 },
    config: { frequency: 0.5 },
  })

  const { y } = useSpring({
    from: { y: 0 },
    to: { y: -100 },
    config: { frequency: 4 },
  })
  const { opacity } = useSpring({
    from: { opacity: 1 },
    to: { opacity: 0 },
    config: { frequency: 4 },
  })

  return <a.div style={{ x, y, opacity }}>{EmojiUnicode[emoji]}</a.div>
}

const initialState = { reactionsIdList: [] }

const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD_EMOJI':
      return {
        ...state,
        reactionsIdList: [action.id, ...state.reactionsIdList],
      }
    case 'REMOVE_EMOJI':
      const { reactionsIdList } = state
      return {
        ...state,
        reactionsIdList: reactionsIdList.filter(id => id != action.id),
      }
    default:
      throw new Error()
  }
}

const Reaction = ({ emoji, isOff, ...rest }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  //Demo add emojis randomly over time
  useEffect(() => {
    if (!isOff) {
      const timeouts = []
      const interval = setInterval(() => {
        const id = uniqueId()
        dispatch({ type: 'ADD_EMOJI', id })
        timeouts.push(
          setTimeout(() => {
            dispatch({ type: 'REMOVE_EMOJI', id })
          }, 4000)
        )
      }, Math.random() * 10000 + 20000 * Math.random())

      return () => {
        clearInterval(interval)
        timeouts.map(clearTimeout)
      }
    }
  }, [isOff])

  return (
    <Stack mb={3} alignSelf="flex-end" gridRow={1} {...rest}>
      {state.reactionsIdList.map(id => (
        <ReactionAnimation emoji={emoji} key={id} />
      ))}
      <IconButton
        aria-label="emoji button"
        icon={<Box ml="3px">{EmojiUnicode[emoji]}</Box>}
        isRound
        onClick={e => {
          e.preventDefault()
          const id = uniqueId()
          dispatch({ type: 'ADD_EMOJI', id })

          //TODO: make this better lol
          setTimeout(() => {
            dispatch({ type: 'REMOVE_EMOJI', id })
          }, 4000)
        }}
      />
    </Stack>
  )
}

type Props = {
  isOff?: boolean
} & StackProps

const ReactionPicker = ({ isOff, ...rest }: Props) => {
  return (
    <Stack isInline spacing={2}>
      <Reaction emoji={Emojis.Fist} isOff={isOff} />
      <Reaction emoji={Emojis.Fire} isOff={isOff} />
      <Reaction emoji={Emojis.Heart} isOff={isOff} />
    </Stack>
  )
}

export default ReactionPicker
