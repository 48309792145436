/** @jsx jsx */

import { jsx, css } from '@emotion/core'
import Message from './Message'
import { useTransition } from 'react-spring/universal.cjs'
import { Stack, StackProps } from '@chakra-ui/core'
import { useMessagesState } from 'messages/MessagesContext'

type Props = {} & StackProps

const MessagesContainer = ({ ...stack }: Props) => {
  const { messages } = useMessagesState()

  // Message is wrapped with a.div
  const transition = useTransition(messages.slice(0, 5), {
    from: { transform: 'translateY(100%)' },
    enter: { transform: 'translateY(0%)' },
  })
  return (
    <Stack
      maxH="9.5rem"
      css={css`
        mask-image: linear-gradient(to bottom, transparent -5%, black 50%);
      `}
      overflow="hidden"
      flexDir="column-reverse"
      flex="1 0 0"
      {...stack}
    >
      {transition((style, item) => {
        return <Message {...item} style={style} />
      })}
    </Stack>
  )
}

export default MessagesContainer
