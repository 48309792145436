/** @jsx jsx */

import React, { ReactNode } from 'react'
import Head from 'next/head'
import { jsx, css, Global } from '@emotion/core'
import { Colors, Fonts } from './css/constants'
import { DarkMode } from '@chakra-ui/core'

const Layout = ({ children }: { children: ReactNode }) => (
  <React.Fragment>
    <Head>
      <link rel="icon" href="/favicon.ico" />

      <title>Stereo: Stream Paid Concerts</title>
      <meta
        property="og:title"
        content="Stereo: Stream Paid Concerts"
        key="title"
      />
    </Head>
    <Global
      styles={css`
        body {
          background-color: ${Colors.BLACK};
          color: rgba(255, 255, 255, 0.87);
          word-break: break-word;
          text-rendering: optimizeLegibility;
          -webkit-font-smoothing: antialiased;
          ${Fonts.AcuminProBlack}
        }
      `}
    />
    {children}
  </React.Fragment>
)

export const LayoutMain = ({
  children,
  className,
}: {
  children: ReactNode
  className?: string
}) => (
  <DarkMode>
    <Layout>
      <main
        css={css`
          display: flex;
          flex-direction: column;
        `}
        className={className}
      >
        {children}
      </main>
    </Layout>
  </DarkMode>
)

export default Layout
